body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slider {
  width: 100% !important;
}

.slider {
  -webkit-appearance: none;
  width: 100% !important;
  height: 15px;
  border-radius: 5px;  
  background: transparent;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%; 
  background: #337ab7;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #337ab7;
  cursor: pointer;
}
input[type=range]::-ms-track {
/* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: lightgray;
}
.jumbotron {
  color: #000
}

h3 select {
  font-size: .8em;
}